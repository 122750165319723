import React from 'react';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router-dom';


/**
 * CHECKED-2021
 *
 * Redirects user to the correct home page.
 * If the user is logged in, they will be sent to: /home
 * If not logged in: /
 */
const PublicHomeRouteManager = ({ component: Component, user_id, ...rest }) => {

    let crswrk_cd = false;
    if(user_id){
        const searchParams = new URLSearchParams(rest.location.search);
        crswrk_cd = searchParams.get("crswrk_cd");
    }

    return   (
        <Route {...rest} render={(props) => (
            !user_id ?
                <Component {...props} />
                : (crswrk_cd?<Redirect to={{pathname: `/coursework/setup/${crswrk_cd}`, state: {from: props.location}}}/>:<Redirect to={{pathname: '/home', state: {from: props.location}}}/>)
        )}/>
    );
};



export default PublicHomeRouteManager;

