
export const WistiaVideoTrackingType = {
    NONE:0,
    PORTAL_VIDEO:1,
    PUBLIC_VIDEO:2,
    PUBLIC_COURSEWORK:3,
};



export const initWistiaGlobalUtils = () => {

    if (!document.getElementById("wistia_script")) {

        //
        // Load E-v1.js
        //
        const wistiaScript = document.createElement("script");
        wistiaScript.id = "wistia_script";
        wistiaScript.type = "text/javascript";
        wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
        wistiaScript.async = true;
        document.body.appendChild(wistiaScript);
    }


    window._wq = window._wq || [];


    if(window._d2WistiaInitProcessed){
        return;
    }

    window._d2WistiaInitProcessed=true;


    //
    // Only run this once.
    //
    window._wq.push({

        id: "_all",

        onReady: (video) => {

            // console.log("global-onready", video.hashedId());

            video.bind("play", function () {

                const allVideos = window.Wistia.api.all();
                for (let i = 0; i < allVideos.length; i++) {

                    // console.log(allVideos[i].hashedId())

                    if (allVideos[i].hashedId() !== video.hashedId()) {
                        allVideos[i].pause();
                    }
                }

                const resourceId = video.container.getAttribute("resourceid");
                // console.log(resourceId);

                if(resourceId){

                    //addRecentlyViewed(resourceId);
                }

            });
        }
    });
};


// export const clearAllWistia = () => {
//     if( window.Wistia ) {
//         const allVideos = window.Wistia.api.all();
//         for (let i = 0; i < allVideos.length; i++) {
//             allVideos[i].remove();
//         }
//     }
// };



export default initWistiaGlobalUtils;


