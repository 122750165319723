import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {RoleTypes} from '../../models/RoleTypes';
// import * as salesTeamRoleType from '../../models/SalesTeamRoleTypes';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';

import RetinaImage from '../RetinaImage/RetinaImage';
import HamburgerIcon from '../icons/MobileHamburger';
import TopMenuCheveron from '../icons/TopMenuChevron';
import SideMenuCheveron from '../icons/SideMenuChevron';
import ProfileImage from '../ProfileImage/ProfileImage';
import FacialTrainingAlert from "../../pages/private/resources/FacialTraining/FacialTrainingAlert";

import classes from './Header.module.scss';




class Header extends Component{


    // constructor(props) {
    //     super(props);
    // }

    state = {

        activeMenuItem:"",
        displayFullMenuMask: false,
        miniMenuOpen:false,
        openedSubMenuId:null,

        showFacialTrainingAlert:false
    };




    goHome = () => {
        this.props.history.push("/home");
    };


    logout = () => {

        // console.log("USER LOGGING OUT - Header");

        this.props.history.push("/logout");
    };








    resetMenu = () => {

        // console.log("Reset Menu called");


        //
        // Don't do anything if mini-menu is open.
        // This would close it.
        //
        if( this.state.miniMenuOpen ){
            return;
        }

        this.setState({
            activeMenuItem:"",
            displayFullMenuMask: false,
            miniMenuOpen:false
        });
    };



    resize = () => {
        this.resetMenu();
    };





    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }



    componentWillUnmount() {

        window.removeEventListener('resize', this.resize);
    }


    componentDidUpdate(prevProps) {

        // console.log("Header updated(new)", this.props.location.pathname)
        // console.log("Header updated(prev)", prevProps.location.pathname)

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.closeMiniNavMenu();
        }
    }



    toggleMenuDropDown = (e,menuDropDownName) => {

        e.stopPropagation();


        let displayFullMenuMask = false;
        let newActiveMenuItem = "";
        if( this.state.activeMenuItem!==menuDropDownName ){
            newActiveMenuItem=menuDropDownName;
            displayFullMenuMask = true;
        }

        // console.log(newActiveMenuItem )


        this.setState({
            displayFullMenuMask: displayFullMenuMask,
            activeMenuItem: newActiveMenuItem
        });

        //
        // If the min-menu is displayed and the screen is resized, close it.
        // See comment in resetMenu()
        //
        this.closeMiniNavMenu();
    };



    closeAllFullNavMenuDropDowns = () => {
        this.setState({
            activeMenuItem:"",
            displayFullMenuMask: false
        });
    };







    goto = (url) => {

        this.closeMiniNavMenu();

        this.props.history.push(url)
    };





    miniMenuControlBtnClicked = (x) => {

        this.setState((prevState)=>{
            return {
                miniMenuOpen: !prevState.miniMenuOpen
            }
        });
    };

    closeMiniNavMenu = () => {

        this.setState({
            openedSubMenuId:null,
            miniMenuOpen:false
        });
    };


    openMiniSubMenu = (menuDropDownName) => {


        let newActiveMenuItem = "";
        if( this.state.activeMenuItem!==menuDropDownName ){
            newActiveMenuItem=menuDropDownName;
        }

        this.setState({
            activeMenuItem: newActiveMenuItem
        });
    };



    determineSelectedMenu = (location) => {

        //console.log("pathname", location.pathname)



        if(location.pathname==="/home/about-us/what-we-do"){
            return "whatWeDoMenu"
        }

        if(location.pathname==="/home/events/live-programs"){
            return "liveProgramsMenu"
        }

        if(location.pathname==="/home/content/my-list"){
            return "myListMenu"
        }

        if(location.pathname.startsWith("/home/content") ){
            return "onDemandProgramsMenu"
        }

        if( location.pathname.startsWith("/home/resources")){
            return "resourcesMenu";
        }

        if( location.pathname.startsWith("/home/support")){
            return "contactUsMenu";
        }

        if( location.pathname.startsWith("/home/sales-team")){
            return "salesTeamMenu";
        }

        if( location.pathname.startsWith("/home/analytics")
            || location.pathname.startsWith("/home/manage") ){
            return "homeOfficeMenu";
        }


        return "";
    };




    openSubMenu = (e,subMenuId) => {

        e.stopPropagation();

        this.setState({
            openedSubMenuId: this.state.openedSubMenuId !== subMenuId ? subMenuId : null
        });
    };


    showFacialTrainingModal = () => {

        this.closeMiniNavMenu();

        this.setState({
            showFacialTrainingAlert:true
        });
    }

    closeFacialTrainingAlert = () => {

        this.closeMiniNavMenu();

        this.setState({
            showFacialTrainingAlert:false
        });
    };


    render() {


        // const isSalesRep = salesTeamRoleType.roleTypeArrayContainsRepRoleType(this.props.sales_team_role_type_array);

        const topMenuSelected = this.determineSelectedMenu(this.props.history.location);

        let displayName = this.props.firstname +" "+this.props.lastname;

        if(displayName.length > 20){
           displayName = this.props.firstname;
        }

        return (
            <React.Fragment>

                <header className={classes.Header} onClick={this.closeAllFullNavMenuDropDowns}>

                    <Container className={classes.fullNavBar}>

                        <Row className={"mt-3"}>
                            <Col sm={4}>
                                <RetinaImage onClick={this.goHome}
                                             width={332}
                                             height={32}
                                             className={classes.logoImg}
                                             src={[
                                    require("../../assets/images/logos/logo-allergan-ko-hd.png").default,
                                    require("../../assets/images/logos/logo-allergan-ko-hd@2x.png").default,
                                    require("../../assets/images/logos/logo-allergan-ko-hd@3x.png").default]}
                                             alt="" />
                            </Col>
                            <Col sm={5}>
                                <RetinaImage onClick={this.goHome}
                                             width={474}
                                             height={17}
                                             className={classes.logoImg}
                                             src={[
                                    require("../../assets/images/logos/logo-pillar-hd.png").default,
                                    require("../../assets/images/logos/logo-pillar-hd@2x.png").default,
                                    require("../../assets/images/logos/logo-pillar-hd@3x.png").default]}
                                             alt="" />
                            </Col>
                            <Col className={classes.rightCol} sm={3}>
                                <div>

                                    <div className={classes.dropdown + " align-middle d-flex"}>
                                        <span className={"mr-2 para-2 flex-wrap "+ classes.headerBarText}>Welcome,<br/>{displayName}</span>
                                        <button className={classes.sidebarButton} onClick={(e)=>this.toggleMenuDropDown(e,"sidebarMenu")}>
                                            <ProfileImage width={50} height={50} user_id={this.props.user_id}/>
                                        </button>

                                        <div className={"medium-brown para-3 "+ classes.dropdownContent + " "+ classes.secondaryMenu + " " +((this.state.activeMenuItem==="sidebarMenu")?classes.openDropDown:"")}>

                                            <div onClick={()=>{this.goto("/home/profile")}} className={classes.menuItem}>My Profile</div>
                                            <div onClick={this.logout} className={classes.menuItem}>Sign Out</div>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>



                        <Row className={classes.menuBar + " pb-2 para-3"}>


                            <div className={classes.dropdown}>
                                <button className={classes.menuBtn}
                                        onClick={()=>this.goto("/home/about-us/what-we-do")}>What We Do</button>
                                {topMenuSelected==="whatWeDoMenu" ? (<div className={classes.menuSelected}/>) : null}
                            </div>




                            <div className={classes.dropdown}>
                                <button className={classes.menuBtn}
                                        onClick={(e)=>this.toggleMenuDropDown(e,"liveProgramsMenu")}>
                                    Live Programs <TopMenuCheveron arrowUp={ this.state.activeMenuItem==="liveProgramsMenu"}/>
                                </button>

                                {topMenuSelected==="liveProgramsMenu" ? (<div className={classes.menuSelected}/>) : null}

                                <div className={classes.dropdownContent + " "+ ((this.state.activeMenuItem==="liveProgramsMenu")?classes.openDropDown:"")}>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/events/live-programs")}} >In-Person & Virtual Live Programs</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/events/request-program")}} >Request a Program</div>
                                </div>
                            </div>



                            <div className={classes.dropdown}>

                                <button className={classes.menuBtn}
                                        onClick={(e)=>this.toggleMenuDropDown(e,"onDemandProgramsMenu")}>
                                    On-Demand Education <TopMenuCheveron arrowUp={this.state.activeMenuItem==="onDemandProgramsMenu"}/>
                                </button>

                                {topMenuSelected==="onDemandProgramsMenu" ? (<div className={classes.menuSelected}/>) : null}

                                <div className={classes.dropdownContent + " "+ ((this.state.activeMenuItem==="onDemandProgramsMenu")?classes.openDropDown:"")}>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/content/videos")}} >Videos</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/content/podcasts")}} >Podcasts</div>
                                </div>
                            </div>


                            <div className={classes.dropdown}>
                                <button className={classes.menuBtn}
                                        onClick={()=>this.goto("/home/content/my-list")}>
                                    <img src={require("../../assets/images/icons/ico-bookmark-active-sm.svg").default}
                                         height={12}
                                         alt=""/> My List
                                </button>
                                {topMenuSelected==="myListMenu" ? (<div className={classes.menuSelected}/>) : null}
                            </div>


                            <div className={classes.dropdown}>
                                <button className={classes.menuBtn}
                                        onClick={(e)=>this.toggleMenuDropDown(e,"resourcesMenu")}>
                                    Resources <TopMenuCheveron arrowUp={this.state.activeMenuItem==="resourcesMenu"}/>
                                </button>

                                {topMenuSelected==="resourcesMenu" ? (<div className={classes.menuSelected}/>) : null}

                                <div className={classes.dropdownContent + " "+ ((this.state.activeMenuItem==="resourcesMenu")?classes.openDropDown:"")}>

                                    {/*<div className={classes.menuItem} onClick={()=>{this.goto("/home/resources")}} >Resources</div>*/}
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/resources/keys-info")}} >KEYS</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/resources/product-info")}} >Product Information</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/resources/programs")}} >Provider Programs</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/resources/social-media")}} >Social Media</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/resources/product-coding-and-reimbursement")}} >Product Reimbursement</div>
                                    {/*<div className={classes.menuItem} onClick={this.showFacialTrainingModal} >Facial Training</div>*/}
                                </div>
                            </div>


                            <div className={classes.dropdown}>
                                <button className={classes.menuBtn}
                                        onClick={(e)=>this.toggleMenuDropDown(e,"contactUsMenu")}>
                                    Contact Us <TopMenuCheveron arrowUp={this.state.activeMenuItem==="contactUsMenu"}/>
                                </button>

                                {topMenuSelected==="contactUsMenu" ? (<div className={classes.menuSelected}/>) : null}

                                <div className={classes.dropdownContent + " "+classes.adjustRight + " "+ ((this.state.activeMenuItem==="contactUsMenu")?classes.openDropDown:"")}>

                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/events/request-program")}} >Request a Program</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/support/contact-my-rep")}} >Contact My Rep</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/support/general-inquiry")}} >General Inquiry</div>
                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/support/technical-support")}} >Technical Support</div>
                                </div>
                            </div>






                            {
                                this.props.role_id===RoleTypes.SALES_TEAM ?
                                    (
                                        <div className={classes.dropdown}>
                                            <button className={classes.menuBtn}
                                                    onClick={(e)=>this.toggleMenuDropDown(e,"salesTeamMenu")}>
                                                Sales Team <TopMenuCheveron arrowUp={this.state.activeMenuItem==="salesTeamMenu"}/>
                                            </button>

                                            {topMenuSelected==="salesTeamMenu" ? (<div className={classes.menuSelected}/>) : null}

                                            <div className={classes.dropdownContent + " "+classes.adjustRight + " "+ ((this.state.activeMenuItem==="salesTeamMenu")?classes.openDropDown:"")}>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/sales-team/customers/search")}} >Customers</div>
                                                {/*<div className={classes.menuItem} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/customer-reporting")}} >Customer Reporting</div>*/}
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/sales-team/email/home")}} >Emails</div>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/reporting-dashboard")}} >Reporting Dashboard</div>

                                            </div>
                                        </div>
                                    ) : null
                            }



                            {
                                this.props.role_id===RoleTypes.MED_ED ?
                                    (
                                        <div className={classes.dropdown}>
                                            <button className={classes.menuBtn}
                                                    onClick={(e)=>this.toggleMenuDropDown(e,"homeOfficeMenu")}>
                                                MedEd <TopMenuCheveron arrowUp={this.state.activeMenuItem==="homeOfficeMenu"}/>
                                            </button>

                                            {topMenuSelected==="homeOfficeMenu" ? (<div className={classes.menuSelected}/>) : null}

                                            <div className={classes.dropdownContent + " "+classes.adjustRight + " "+ ((this.state.activeMenuItem==="homeOfficeMenu")?classes.openDropDown:"")}>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/sales-team/customers/search")}} >Customers</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/notify-user-report")}} >Customer Requests</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/sales-team/email/home")}} >Emails</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/evaluations-v2/landing")}} >Evaluations</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/evaluations/list")}} >Evaluations (Legacy)</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-content/search")}} >Manage Content</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-coursework/list")}} >Manage Coursework</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-events/list")}} >Manage Events</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-users/search")}} >Manage Users</div>


                                                {/*<div className={classes.menuItem+" "+classes.subMenuParent} onClick={(e)=>{this.openSubMenu(e,"reporting")}}>Reporting <TopMenuCheveron arrowUp={this.state.openedSubMenuId==="reporting"}/></div>*/}
                                                {/*{this.state.openedSubMenuId==="reporting"?*/}
                                                {/*    (*/}
                                                {/*        <React.Fragment>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("home/reporting-dashboard/customer-reports/customer-reporting")}} >Customer Reporting</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/analytics/media/media-overview")}} >Media Analytics</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-overview")}} >Media Analytics Details</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-analytics-report")}} >Media by Faculty</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-faculty-report")}} >Media Ratings</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-rating-report")}} >Media User Report</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-user-report")}} >Opt-Out Report</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/sales-email")}} >Sales Email Report</div>*/}
                                                {/*            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/territory-metrics")}} >Sales Team Metrics</div>*/}
                                                {/*        </React.Fragment>*/}
                                                {/*    )*/}
                                                {/*    : null*/}
                                                {/*}*/}

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/reporting-dashboard")}} >Reporting Dashboard</div>
                                            </div>
                                        </div>
                                    ) : null
                            }




                            {
                                this.props.role_id===RoleTypes.ADMIN ?
                                    (
                                        <div className={classes.dropdown}>
                                            <button className={classes.menuBtn}
                                                    onClick={(e)=>this.toggleMenuDropDown(e,"adminMenu")}>
                                                Admin <TopMenuCheveron arrowUp={this.state.activeMenuItem==="adminMenu"}/>
                                            </button>

                                            {topMenuSelected==="adminMenu" ? (<div className={classes.menuSelected}/>) : null}

                                            <div className={classes.dropdownContent + " "+classes.adjustRight + " "+ ((this.state.activeMenuItem==="adminMenu")?classes.openDropDown:"")}>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/sales-team/customers/search")}} >Customers</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/notify-user-report")}} >Customer Requests</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/sales-team/email/home")}} >Emails</div>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/evaluations-v2/landing")}} >Evaluations</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/evaluations/list")}} >Evaluations (Legacy)</div>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-content/search")}} >Manage Content</div>

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-coursework/list")}} >Manage Coursework</div>
                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-events/list")}} >Manage Events</div>

                                                {this.props.user_id===1?
                                                    <div className={classes.menuItem} onClick={()=>{this.goto("/home/admin/system-admin/task-manager")}} >Manage Tasks</div>
                                                : null }


                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/manage/manage-users/search")}} >Manage Users</div>

                                                <div className={classes.menuItem+" "+classes.subMenuParent} onClick={(e)=>{this.openSubMenu(e,"reporting")}}>Reporting <TopMenuCheveron arrowUp={this.state.openedSubMenuId==="reporting"}/></div>
                                                {this.state.openedSubMenuId==="reporting"?
                                                    (
                                                        <React.Fragment>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/customer-reporting")}} >Customer Reporting</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/analytics/events/events-report/overview")}} >Event Analytics</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-overview")}} >Media Analytics</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-analytics-report")}} >Media Analytics Details</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-faculty-report")}} >Media by Faculty</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-rating-report")}} >Media Ratings</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-user-report")}} >Media User Report</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/opt-out-report")}} >Opt-Out Report</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/sales-email-overview")}} >Sales Email Report</div>
                                                            <div className={classes.menuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/territory-metrics")}} >Sales Team Metrics</div>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                                }

                                                <div className={classes.menuItem} onClick={()=>{this.goto("/home/reporting-dashboard")}} >Reporting Dashboard</div>

                                            </div>
                                        </div>
                                    ) : null
                            }

                        </Row>

                    </Container>


                    <Container fluid className={classes.miniNavBar}>

                        <Row className={classes.miniMenuBar+" flex-nowrap align-items-center"}>
                            <Col className={"align-items-center"}>
                                <RetinaImage onClick={this.goHome}
                                             className={classes.largeLogoLeftVisible+" "+classes.logoImg}
                                             width={332}
                                             height={32}
                                             src={[
                                                 require("../../assets/images/logos/logo-allergan-ko-hd.png").default,
                                                 require("../../assets/images/logos/logo-allergan-ko-hd@2x.png").default,
                                                 require("../../assets/images/logos/logo-allergan-ko-hd@3x.png").default]}
                                             alt="" />

                                <RetinaImage className={classes.miniNavLogo + " img-fluid "+classes.smallLogoLeftVisible+" "+classes.logoImg}
                                             width={80}
                                             height={65}
                                             onClick={this.goHome}
                                             src={[
                                                 require("../../assets/images/logos/logo-allergan-stacked-ko.png").default,
                                                 require("../../assets/images/logos/logo-allergan-stacked-ko@2x.png").default,
                                                 require("../../assets/images/logos/logo-allergan-stacked-ko@3x.png").default]}
                                             alt="Allergan Medical Institue" />
                            </Col>

                            <Col className={"d-flex align-items-center justify-content-center"}>


                                <RetinaImage onClick={this.goHome}
                                             className={classes.largeLogoMiddleVisible+" "+classes.logoImg}
                                             width={474}
                                             height={17}
                                             src={[
                                                 require("../../assets/images/logos/logo-pillar-hd.png").default,
                                                 require("../../assets/images/logos/logo-pillar-hd@2x.png").default,
                                                 require("../../assets/images/logos/logo-pillar-hd@3x.png").default]}
                                             alt="" />


                                <RetinaImage className={classes.miniNavLogo + " img-fluid "+classes.smallLogoMiddleVisible+" "+classes.logoImg}
                                             onClick={this.goHome}
                                             width={147}
                                             height={48}
                                             src={[
                                    require("../../assets/images/logos/logo-pillar-stacked.png").default,
                                    require("../../assets/images/logos/logo-pillar-stacked@2x.png").default,
                                    require("../../assets/images/logos/logo-pillar-stacked@3x.png").default]}
                                             alt="" />
                            </Col>

                            <Col className={classes.rightCol}>
                                <div className={classes.miniMenuControlButton} onClick={this.miniMenuControlBtnClicked}>

                                    <HamburgerIcon open={!this.state.miniMenuOpen}/>
                                </div>
                            </Col>
                        </Row>

                    </Container>


                    <div className={classes.bottomDivider}/>

                </header>




                {this.state.miniMenuOpen?(
                    <Container fluid className={classes.miniMenuSection}>
                        <div className={"para-3 " + classes.miniMenu } >

                            <div className={classes.miniMenuItem} onClick={()=>{this.goto("/home/profile")}} >
                                <span className={"p-regular " + classes.headerBarText}>
                                    Welcome, {this.props.firstname +" "+this.props.lastname}
                                </span>
                                <span>
                                    <ProfileImage className={classes.miniMenuItmProfile} width={45} height={45} user_id={this.props.user_id}/>
                                </span>
                            </div>


                            <div className={classes.miniMenuItem}
                                 onClick={()=>{this.goto("/home/about-us/what-we-do")}} >
                                <div className={"mx-0 px-0"}>
                                    What We Do
                                </div>
                            </div>



                            <div className={classes.miniMenuItem}  onClick={()=>{this.openMiniSubMenu("liveProgramsMenu")}}>
                                <span>
                                    Live Programs
                                </span>
                                <span>
                                    <SideMenuCheveron state={(this.state.activeMenuItem==="liveProgramsMenu")?"open":"closed"}/>
                                </span>
                            </div>
                            <Collapse in={this.state.activeMenuItem==="liveProgramsMenu"}>
                                <div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/events/live-programs")}}>
                                        In-Person & Virtual Live Programs
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/events/request-program")}}>
                                        Request a Program
                                    </div>
                                </div>
                            </Collapse>


                            <div className={classes.miniMenuItem}  onClick={()=>{this.openMiniSubMenu("onDemandProgramsMenu")}}>
                                <span>
                                    On-Demand Education
                                </span>
                                <span>
                                    <SideMenuCheveron state={(this.state.activeMenuItem==="onDemandProgramsMenu")?"open":"closed"}/>
                                </span>
                            </div>
                            <Collapse in={this.state.activeMenuItem==="onDemandProgramsMenu"}>
                                <div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/content/videos")}}>
                                        Videos
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/content/podcasts")}}>
                                        Podcasts
                                    </div>
                                </div>
                            </Collapse>




                            <div className={classes.miniMenuItem}
                                 onClick={()=>{this.goto("/home/content/my-list")}} >
                                <div className={"mx-0 px-0"}>
                                    <img src={require("../../assets/images/icons/ico-bookmark-active-sm.svg").default}
                                         height={12}
                                         alt=""/>
                                    My List
                                </div>
                            </div>


                            <div className={classes.miniMenuItem} onClick={()=>{this.openMiniSubMenu("resourcesMenu")}}>
                                <span>
                                    Resources
                                </span>
                                    <span>
                                        <SideMenuCheveron state={(this.state.activeMenuItem==="resourcesMenu")?"open":"closed"}/>
                                </span>
                            </div>

                            <Collapse in={this.state.activeMenuItem==="resourcesMenu"}>
                                <div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/resources/keys-info")}}>
                                        KEYS
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/resources/product-info")}}>
                                        Product Information
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/resources/programs")}}>
                                        Programs
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/resources/social-media")}}>
                                        Social Media
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/resources/product-coding-and-reimbursement")}}>
                                        Product Reimbursement
                                    </div>
                                    {/*<div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={this.showFacialTrainingModal}>*/}
                                    {/*    Facial Training*/}
                                    {/*</div>*/}
                                </div>
                            </Collapse>




                            <div className={classes.miniMenuItem}  onClick={()=>{this.openMiniSubMenu("contactUsMenu")}}>
                                <span>
                                    Contact Us
                                </span>
                                <span>
                                    <SideMenuCheveron state={(this.state.activeMenuItem==="contactUsMenu")?"open":"closed"}/>
                                </span>
                            </div>
                            <Collapse in={this.state.activeMenuItem==="contactUsMenu"}>
                                <div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/events/request-program")}}>
                                        Request a Program
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/support/contact-my-rep")}}>
                                        Contact My Rep
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/support/general-inquiry")}}>
                                        General Inquiry
                                    </div>
                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/support/technical-support")}}>
                                        Technical Support
                                    </div>
                                </div>
                            </Collapse>






                            {
                                this.props.role_id===RoleTypes.SALES_TEAM ?
                                    (
                                        <React.Fragment>
                                            <div className={classes.miniMenuItem}  onClick={()=>{this.openMiniSubMenu("salesTeamMenu")}}>
                                                <span>
                                                    Sales Team
                                                </span>
                                                <span>
                                                    <SideMenuCheveron state={(this.state.activeMenuItem==="salesTeamMenu")?"open":"closed"}/>
                                                </span>
                                            </div>
                                            <Collapse in={this.state.activeMenuItem==="salesTeamMenu"}>
                                                <div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/sales-team/customers/search")}}>
                                                        Customers
                                                    </div>
                                                    {/*<div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/customer-reporting")}}>*/}
                                                    {/*    Customer Reporting*/}
                                                    {/*</div>*/}
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/sales-team/email/home")}}>
                                                        Emails
                                                    </div>

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/reporting-dashboard")}}>
                                                        Reporting Dashboard
                                                    </div>

                                                </div>
                                            </Collapse>
                                        </React.Fragment>
                                    ) : null
                            }







                            {
                                this.props.role_id===RoleTypes.MED_ED ?
                                    (
                                        <React.Fragment>
                                            <div className={classes.miniMenuItem}  onClick={()=>{this.openMiniSubMenu("homeOfficeMenu")}}>
                                                <span>
                                                    MedEd
                                                </span>
                                                <span>
                                                    <SideMenuCheveron state={(this.state.activeMenuItem==="homeOfficeMenu")?"open":"closed"}/>
                                                </span>
                                            </div>
                                            <Collapse in={this.state.activeMenuItem==="homeOfficeMenu"}>
                                                <div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/sales-team/customers/search")}}>
                                                        Customers
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/notify-user-report")}}>
                                                        Customer Requests
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/sales-team/email/home")}}>
                                                        Emails
                                                    </div>

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/evaluations-v2/landing")}}>
                                                        Evaluations
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/evaluations/list")}}>
                                                        Evaluations (Legacy)
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-content/search")}}>
                                                        Manage Content
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-coursework/list")}}>
                                                        Manage Coursework
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-events/list")}}>
                                                        Manage Events
                                                    </div>

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-users/search")}}>
                                                        Manage Users
                                                    </div>


                                                    {/*<div className={classes.miniMenuItem+" "+classes.subMenuParent + " " + classes.subMenu1} onClick={(e)=>{this.openSubMenu(e,"reporting")}}>*/}
                                                    {/*    Reporting <TopMenuCheveron arrowUp={this.state.openedSubMenuId==="reporting"}/>*/}
                                                    {/*</div>*/}
                                                    {/*{this.state.openedSubMenuId==="reporting"?*/}
                                                    {/*    (*/}
                                                    {/*        <React.Fragment>*/}
                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/customer-reporting")}}>*/}
                                                    {/*                Customer Reporting*/}
                                                    {/*            </div>*/}
                                                    {/*            /!*<div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/analytics/events/events-report")}}>*!/*/}
                                                    {/*                /!*Event Analytics*!/*/}
                                                    {/*            /!*</div>*!/*/}
                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-overview")}}>*/}
                                                    {/*                Media Analytics*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-analytics-report")}}>*/}
                                                    {/*                Media Analytics Details*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-faculty-report")}}>*/}
                                                    {/*                Media by Faculty*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-rating-report")}}>*/}
                                                    {/*                Media Ratings*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-user-report")}}>*/}
                                                    {/*                Media User Report*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/opt-out-report")}}>*/}
                                                    {/*                Opt-Out Report*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/sales-email")}}>*/}
                                                    {/*                Sales Email Report*/}
                                                    {/*            </div>*/}

                                                    {/*            <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/territory-metrics")}}>*/}
                                                    {/*                Sales Team Metrics*/}
                                                    {/*            </div>*/}

                                                    {/*        </React.Fragment>*/}
                                                    {/*    )*/}
                                                    {/*    : null*/}
                                                    {/*}*/}

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/reporting-dashboard")}}>
                                                        Reporting Dashboard
                                                    </div>

                                                </div>
                                            </Collapse>
                                        </React.Fragment>
                                    ) : null
                            }



                            {
                                this.props.role_id===RoleTypes.ADMIN ?
                                    (
                                        <React.Fragment>
                                            <div className={classes.miniMenuItem}  onClick={()=>{this.openMiniSubMenu("adminMenu")}}>
                                                <span>
                                                    Admin
                                                </span>
                                                <span>
                                                    <SideMenuCheveron state={(this.state.activeMenuItem==="adminMenu")?"open":"closed"}/>
                                                </span>
                                            </div>
                                            <Collapse in={this.state.activeMenuItem==="adminMenu"}>
                                                <div>

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/sales-team/customers/search")}}>
                                                        Customers
                                                    </div>

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/notify-user-report")}}>
                                                        Customer Requests
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/sales-team/email/home")}}>
                                                        Emails
                                                    </div>

                                                        <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/evaluations-v2/landing")}}>
                                                            Evaluations
                                                        </div>
                                                        <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/evaluations/list")}}>
                                                            Evaluations (Legacy)
                                                        </div>


                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-content/search")}}>
                                                        Manage Content
                                                    </div>

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-coursework/list")}}>
                                                        Manage Coursework
                                                    </div>
                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-events/list")}}>
                                                        Manage Events
                                                    </div>


                                                    {this.props.user_id===1?
                                                        <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/admin/system-admin/task-manager")}}>
                                                            Manage Tasks
                                                        </div>
                                                    :null}

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/manage/manage-users/search")}}>
                                                        Manage Users
                                                    </div>


                                                    <div className={classes.miniMenuItem+" "+classes.subMenuParent + " " + classes.subMenu1} onClick={(e)=>{this.openSubMenu(e,"reporting")}}>
                                                        Reporting <TopMenuCheveron arrowUp={this.state.openedSubMenuId==="reporting"}/>
                                                    </div>
                                                    {this.state.openedSubMenuId==="reporting"?
                                                        (
                                                            <React.Fragment>
                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/customer-reporting")}}>
                                                                    Customer Reporting
                                                                </div>
                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/analytics/events/events-report")}}>
                                                                    Event Analytics
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-overview")}}>
                                                                    Media Analytics
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-analytics-report")}}>
                                                                    Media Analytics Details
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-faculty-report")}}>
                                                                    Media by Faculty
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-rating-report")}}>
                                                                    Media Ratings
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/media-reports/media-user-report")}}>
                                                                    Media User Report
                                                                </div>


                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/customer-reports/opt-out-report")}}>
                                                                    Opt-Out Report
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/sales-email-overview")}}>
                                                                    Sales Email Report
                                                                </div>

                                                                <div className={classes.miniMenuItem+" "+classes.subMenu2} onClick={()=>{this.goto("/home/reporting-dashboard/sales-rep-reports/territory-metrics")}}>
                                                                    Sales Team Metrics
                                                                </div>

                                                            </React.Fragment>
                                                        )
                                                        : null
                                                    }

                                                    <div className={classes.miniMenuItem + " " + classes.subMenu1} onClick={()=>{this.goto("/home/reporting-dashboard")}}>
                                                        Reporting Dashboard
                                                    </div>

                                                </div>
                                            </Collapse>
                                        </React.Fragment>
                                    ) : null
                            }

                            <div className={classes.miniMenuItem} onClick={()=>{this.goto("/home/profile")}}>
                                My Profile
                            </div>
                            <div className={classes.miniMenuItem} onClick={this.logout}>
                                Sign Out
                            </div>
                        </div>
                    </Container>):null}


                {this.state.miniMenuOpen?(<div className={classes.miniMenuMask} onClick={this.closeMiniNavMenu}/>):null}

                {this.state.displayFullMenuMask?(<div className={classes.fullNavBarMask} onClick={this.closeAllFullNavMenuDropDowns}/>):null}

                <FacialTrainingAlert show={this.state.showFacialTrainingAlert}
                             onClose={this.closeFacialTrainingAlert} />
            </React.Fragment>
        )
    }
}





const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id,
        username: state.security.username,
        firstname: state.security.firstname,
        lastname: state.security.lastname,
        sales_team_role_type_array: state.security.sales_team_role_type_array
    }
};


export default connect(mapStateToProps, null)(withRouter(Header));




