import React, {Component} from 'react';
import {connect} from 'react-redux';

import firebase from 'firebase/app';
import "firebase/analytics";

import * as actions from "../../store/actions";
import * as errorHelper from "../../utils/error-helper";
import axios from "../../utils/config-axios";

// import {addRecentlyViewed} from '../../pages/Recent/recently-viewed-utils';
// import CustomFullScreenButton from './CustomFullScreenButton';

import classes from './WistiaManager.module.scss';
import {WistiaVideoTrackingType} from "./wistia-utils";

//
// DO NOT CHANGE THIS!!!!
// Once set it should stay this value forever!!!!
//
const SLOT_TIME_INTERVAL = 20;
const __WISTIA_MANAGER_TRACKER_LOCAL_STORAGE_KEY = "wistia_manager_tracker"

class WistiaManager extends Component{


    constructor(props) {
        super(props);

        this.initWistiaGlobalUtils();
    }


    initWistiaGlobalUtils = () => {

        if (!document.getElementById("wistia_script") ) {

            //
            // Load E-v1.js
            //
            const wistiaScript = document.createElement("script");
            wistiaScript.id = "wistia_script";
            wistiaScript.type = "text/javascript";
            wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
            wistiaScript.async = true;
            document.body.appendChild(wistiaScript);
        }


        window._wq = window._wq || [];


        if(window._d2WistiaInitProcessed){
            return;
        }

        window._d2WistiaInitProcessed=true;


        //
        // Load custom buttons or plugins.
        //
        // window.wistiaInitQueue = window.wistiaInitQueue || [];
        // window.wistiaInitQueue.push( (W) => {
        //     window.Wistia.defineControl(CustomFullScreenButton);
        // });


        //
        // Only run this once.
        //
        window._wq.push({

            id: "_all",

            options: {
                // autoPlay: true,
                controlsVisibleOnLoad: true,
                // email: "lennythedog@wistia.com",
                endVideoBehavior: "reset",
                fullscreenButton: true,
                resumable: true,
                videoFoam: true,
                playbackRateControl:false,
                qualityControl:false,
                // customFullScreenButton: true,
                // fakeFullscreen: true,
                // googleAnalytics: true,
                // playButton: false,
                // playbar: false,
                //playerColor: "#FF00FF",
                playerColor: "#071d49",
                // seo: true,
                // stillUrl: "https://my-awesome-website.com/my-thumbnail-url.jpg",
                //stillUrl:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-d2-dev-6a857.appspot.com/o/content%2Fdocuments%2F42%2F1024haywardLogo.png?alt=media",
                // volume: 0.5,
                wmode: "transparent"
            },

            onReady: (video) => {

                // console.log("global-onready", video.hashedId());
                // console.log("username",this.props.username);

                //
                // Set email/username for Wistia tracking.
                //
                const wrapperDiv = document.getElementById("wistia_wrapper_"+video.hashedId());
                const tracking_email = wrapperDiv.getAttribute("data-tracking_email");

                if(tracking_email){
                    //
                    // Check if there is a tracking email assigned.
                    //
                    video.email(tracking_email);
                } else if(this.props.username){
                    //
                    // Use the logged-in user's username.
                    //
                    video.email(this.props.username);
                } else {
                  video.email(null);
                }


                video.bind("play", () => {

                    try {

                        // console.log("WistiaManager - Playing:", video.hashedId(), video.duration());

                        const wrapperDiv = document.getElementById("wistia_wrapper_"+video.hashedId());

                        if( !wrapperDiv ){
                            throw new Error("Wistia wrapper div not found. Using hashedId:"+video.hashedId());
                        }

                        const content_id = wrapperDiv.getAttribute("data-content_id");
                        // console.log("WistiaManager - content_id",content_id);
                        video.__ami_content_id = parseInt(content_id);


                        const tracking_type = wrapperDiv.getAttribute("data-tracking_type");
                        //console.log("WistiaManager - tracking_type",tracking_type);
                        if(tracking_type){
                            video.__ami_tracking_type = parseInt(tracking_type);
                        } else {
                            video.__ami_tracking_type = null;
                        }

                        // RJS-2024 - DELETE FROM SRC IN FUTURE!!!
                        // const tracking_email = wrapperDiv.getAttribute("data-tracking_email");
                        // if(tracking_email){
                        //     video.__ami_tracking_email = tracking_email;
                        // } else {
                        //     if(this.props.username){
                        //         video.__ami_tracking_email = this.props.username;
                        //     }else{
                        //         video.__ami_tracking_email = null;
                        //     }
                        // }


                        //
                        // Check if there is a previous tracking slots in the local storage.
                        // This is used to keep the local state of tracking if there is
                        // an issue with the server or network.
                        //
                        let startingTrackingSlots=[];
                        const wmlsKey = this.getWistiaManagerLocalStorageKey(video.hashedId(), video.email());
                        const previousTrackingSlots = localStorage.getItem(wmlsKey);
                        if(previousTrackingSlots){
                            try{
                                console.log(`WistiaManager - Using tracking slots in local storage:[hashed_id:${video.hashedId()}]`)

                                const splitArray = previousTrackingSlots.split(',');
                                splitArray.forEach((row,index)=>{
                                    if(row){
                                        startingTrackingSlots[index] = parseInt(row);
                                    }
                                });
                            }catch(pts_error){
                                // Log it...
                                startingTrackingSlots = [];

                                console.log("WistiaManager - Failed to parse previous tracking slots from local storage.", pts_error)
                            }
                        }

                        // console.log("startingTrackingSlots",startingTrackingSlots);

                        video.__ami_trackVideo = this.trackVideo;

                        const numSlot = Math.floor(video.duration() / SLOT_TIME_INTERVAL);

                        const startingTrackingSlotsLength = startingTrackingSlots.length;
                        if(startingTrackingSlotsLength!==0
                            && startingTrackingSlotsLength!==numSlot+1){
                            console.log("WistiaManager - Ignoring previous saved tracking slots. startingTrackingSlots.length != numSlots.")
                            // console.log("startingTrackingSlots.length",startingTrackingSlotsLength)
                            // console.log("numSlot",numSlot)
                            //
                            // Reset to an empty array.
                            //
                            startingTrackingSlots=[];
                        }

                        video.__ami_trackingSlots = startingTrackingSlots;
                        // video.__ami_trackingSlots = [];
                        video.__ami_trackingSlots[numSlot]=0; // Set the last slot to zero.
                        video.__ami_trackingSlotsCount = [];

                        video.__ami_previous_time = undefined;
                        video.__ami_seeking = false;

                        this.trackVideo(video,"p",video.__ami_trackingSlots);


                        //
                        // Stop any other videos playing...
                        //
                        const allVideos = window.Wistia.api.all();
                        for (let i = 0; i < allVideos.length; i++) {

                            // console.log(allVideos[i].hashedId())

                            if (allVideos[i].hashedId() !== video.hashedId()) {

                                try {
                                    allVideos[i].pause();
                                } catch(error){
                                    console.log("Failed to pause running video:", allVideos[i].hashedId())
                                }
                            }
                        }



                        this.props.onSetPlayingVideo(true, video.hashedId(), content_id);

                        //
                        // Firebase tracking
                        //
                        firebase.analytics().logEvent('view_content', {
                            content_type: 'video',
                            content_id: content_id,
                            wistia_code: video.hashedId()
                        });

                    } catch (error) {
                        console.log("WistiaManager - Error detected during 'play' event.", error);
                    }
                });









                video.bind("pause", () => {

                    try {
                        // console.log("The video was just paused!");

                        //
                        // On pause, set the slot to viewed.
                        //
                        const slot = Math.floor(video.time() / SLOT_TIME_INTERVAL);

                        if (!video.__ami_trackingSlots) {
                            console.log("WistiaManager - Error detected during 'pause' event. __ami_trackingSlots is not defined.");
                            return;
                            // throw new Error("__ami_trackingSlots is not defined.");
                        }

                        video.__ami_trackingSlots[slot] = 1;
                        video.__ami_trackVideo(video, "s", video.__ami_trackingSlots);

                        this.props.onSetPlayingVideo(false, null, null);

                    } catch(error){
                        console.log("WistiaManager - Error detected during 'pause' event.", error);
                    }
                });

                video.bind("end", () => {

                    try {
                        // console.log("The video ended!", video.time(), video.duration(), video.length);

                        //
                        // On end, set the slot to viewed.
                        //
                        const numSlot = Math.floor(video.duration() / SLOT_TIME_INTERVAL);

                        if (!video.__ami_trackingSlots) {

                            console.log("WistiaManager - Error detected during 'end' event. __ami_trackingSlots is not defined.");
                            return;
                            // throw new Error("__ami_trackingSlots is not defined.");
                        }


                        video.__ami_trackingSlots[numSlot] = 1;
                        video.__ami_trackVideo(video, "e", video.__ami_trackingSlots);

                        this.props.onSetPlayingVideo(false, null, null);


                    } catch(error){
                        console.log("WistiaManager - Error detected during 'end' event.", error);
                    }
                });


                //
                // NOTE: "Seek" event will not work correctly since it occurs last
                //      in the chain of events.
                //
                // video.bind("seek", function(currentTime, lastTime) {
                //     console.log("Whoa, you jumped " + Math.abs(lastTime - currentTime) + " seconds!");
                // });



                video.bind("timechange", function(t) {
                    //
                    // In this event, try to detect a seek.
                    // If detected, set a var on the video player.
                    // The "seek" event in Wistia API would not work
                    // since it's called after "secondchange" event.
                    //

                    //console.log("timechange", t);

                    const previousTime = video.__ami_previous_time;
                    //console.log(`[seconds:${t}][previous:${previousTime}]`);

                    video.__ami_previous_time = t;

                    if(previousTime===undefined){
                        //
                        // undefined is okay.
                        // Typically mean player is starting.
                        //
                        //console.log("IS UNDEFINED");
                    } else if(previousTime>t+3 || previousTime<t-3){
                        //
                        // Out of bounds...
                        //
                        video.__ami_seeking=true;
                        //console.log(`Previous time is out of bounds of current time. Seeking in progress:[t=${t}][previousTime=${previousTime}]`);
                        return;
                    }

                    //
                    // Not seeking...
                    //
                    video.__ami_seeking=false;
                });



                video.bind("secondchange", function(s) {

                    // For now, log statement.
                    console.log(`WistiaManager - secondchange event:[hashedId:${video.hashedId()}][s:${s}][seeking:${video.__ami_seeking}],[${!!video.__ami_trackingSlotsCount}][${!!video.__ami_trackVideo}]`);

                    //console.log("video.state", video.state())
                    //console.log("video.state", video.__ami_trackingSlots)

                    if(video.state()==='paused'){
                        //
                        // Video is paused. This happens when the user
                        // pauses the video but performs a seek.
                        //
                        console.log("WistiaManager - Video state is paused. Not recording secondchange.", video.hashedId());
                        return;
                    }

                    // console.log("Is seeking", video.__ami_seeking)
                    if(video.__ami_seeking){
                        //
                        // If user is seeking while video is playing
                        // don't track.
                        //
                        console.log(`Seeking in progress...`);
                        return;
                    }

                    try {
                        // console.log("Second change",s);
                        // console.log("", video.time(), video.duration());

                        const slot = Math.floor(video.time() / SLOT_TIME_INTERVAL);

                        // console.log("slot", slot);
                        // console.log("video.hashedId()", video.hashedId());
                        //
                        // WARNING: Its possible "secondchange" event can be called before
                        // the "play" event is called.
                        // The "play" event would initialize the __ami_trackingSlotsCount.
                        // DO NOT through an ERROR. This will block the "secondchange" event
                        // handler from being called.
                        //
                        if(!video.__ami_trackingSlotsCount){
                            console.log("WistiaManager - Error detected during 'secondchange' event. __ami_trackingSlotsCount is not defined. hashedId=", video.hashedId());
                            return;
                            // throw new Error("__ami_trackingSlotsCount is not defined.")
                        }
                        if(!video.__ami_trackVideo){
                            console.log("WistiaManager - Error detected during 'secondchange' event. __ami_trackVideo is not defined. hashedId=", video.hashedId());
                            return;
                            // throw new Error("__ami_trackVideo is not defined.")
                        }


                        //
                        // Keep count of the number of times the slot has been called.
                        // If 3 or more, set the slot as viewed.
                        // This is required to prevent the scroll being moved back and forth
                        // on the timeline.
                        //
                        //console.log(`[slot=${slot}][count=${video.__ami_trackingSlotsCount[slot]}][value=${video.__ami_trackingSlots[slot]}]`);

                        if(video.__ami_trackingSlotsCount[slot]===undefined || video.__ami_trackingSlotsCount[slot]===null){
                            video.__ami_trackingSlotsCount[slot] = 1;
                        } else {
                            video.__ami_trackingSlotsCount[slot] = video.__ami_trackingSlotsCount[slot]+1;

                            if(video.__ami_trackingSlotsCount[slot]>3){

                                const value = video.__ami_trackingSlots[slot];
                                if(value===undefined || value===null || value===''){

                                    // console.log("video.__ami_trackingSlotsCount[slot]",
                                    //     slot,
                                    //     video.__ami_trackingSlotsCount[slot],
                                    //     video.__ami_trackingSlots[slot])

                                    //
                                    // Only send if slot value is empty.
                                    // Don't overload the server every second!!!
                                    // If during the play() event local storage
                                    // pulls vector, that will be pushed to server
                                    // during the "p" event and during the "e" event.
                                    //
                                    video.__ami_trackingSlots[slot] = 1;

                                    video.__ami_trackVideo(video, "t", video.__ami_trackingSlots);
                                }
                            }
                        }

                        // console.log(video.__ami_trackingSlots);

                        // if (s % 15 === 0 ) {
                        //
                        //     const watchedVector = video.secondsWatchedVector();
                        //     video.__ami_trackVideo(video.hashedId(),"t",watchedVector);
                        // }

                    } catch(error){

                        console.log("WistiaManager - Error detected during 'secondchange' event: hashedId=", video.hashedId());
                        console.log("WistiaManager - Error detected during 'secondchange' event.", error);
                    }
                });
            }
        });
    };



    trackVideo = (video, action, vector) => {

        if(!vector){
            vector=[];
        }

        const numSlot = Math.floor(video.duration() / SLOT_TIME_INTERVAL);

        const data = {
            wistia_hashed_id:video.hashedId(),
            user_id:this.props.security.user_id,
            tracking_type: video.__ami_tracking_type,
            tracking_email: video.email(),
            action:action,
            vector:vector.toString(),
            payload:{
                content_id: (video.__ami_content_id)?video.__ami_content_id:null,
                duration:video.duration(),
                num_slots: numSlot,
                interval: SLOT_TIME_INTERVAL
            }
        };

        try{
            //
            // Save vector to local storage.
            //
            const wmlsKey = this.getWistiaManagerLocalStorageKey(video.hashedId(), video.email());
            localStorage.setItem(wmlsKey, data.vector.toString());
        }catch(wm_svt_error){
            console.log("WistiaManager - Failed to save tracking data to local storage.",wm_svt_error)
        }

        console.log("WistiaManager - Sending video-tracker data...", video.hashedId(), data);
        // console.log("WistiaManager - Sending video-tracker data...", video.hashedId());
        //console.log("WistiaManager - Sending video-tracker data:", video.hashedId(), data.action, data.payload);

        let url = '/api/private/content/video/video-tracker';
        if(data.tracking_type===WistiaVideoTrackingType.PUBLIC_COURSEWORK){
            url = '/api/public/video/tracking/video-tracker';
        }

        // console.log("WistiaManager - url & data",url, data);


        axios({
            url: url,
            method: 'post',
            timeout: 1000 * 10, // Wait for 10 seconds
            data:data
        }).catch(error=>{
            console.log("Failed to send video tracking stats.", errorHelper.buildLogMessage(error));
        });
    };


    getWistiaManagerLocalStorageKey = (wistia_hashed_id, email) => {
        return `${__WISTIA_MANAGER_TRACKER_LOCAL_STORAGE_KEY}-${wistia_hashed_id}-${email}`
    }

    render() {

        if( this.props.supportBackdrop ){
            const classArray = [classes.Backdrop];
            return (
                this.props.isVideoPlaying ? <div className={classArray} onClick={this.props.clicked}></div> : null
            );
        }

        // No backdrop
        return null;
    }
}


const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        username: state.security.username,
        isVideoPlaying: state.video.isVideoPlaying,
        playingVideoWistiaHashedId: state.video.playingVideoWistiaHashedId,
        playingVideoContentId: state.video.playingVideoContentId,
        security:state.security
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlayingVideo: (isVideoPlaying, playingVideoWistiaHashedId, playingVideoContentId) => {
            dispatch( actions.setPlayingVideo(isVideoPlaying, playingVideoWistiaHashedId, playingVideoContentId) );
        }
    }

};


export default connect(mapStateToProps, mapDispatchToProps)(WistiaManager);

