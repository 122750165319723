

//
// Frontend Configuration File.
//
// Note: DO NOT PUT ANY CONFIDENTIAL DATA IN THIS CONFIG.
//       ITS EXPOSED TO THE PUBLIC.
//

const ENVIRONMENT_TYPES = {
    DEV_LOCAL:1,
    DEV_REMOTE:2,
    STAGE:3,
    PRODUCTION:4,
    WARNING__LOCAL_PRODUCTION:5
};


// For deploying to dev/qa this must be set to true.
const ENVIRONMENT = ENVIRONMENT_TYPES.DEV_REMOTE;




export const getSiteConfig = ()=>{
    if(ENVIRONMENT===ENVIRONMENT_TYPES.DEV_LOCAL){
        //
        // Development config.
        //
        return {

            APP_ENGINE_SERVICES_URL:"http://localhost:8089",
            APP_ENGINE_TASKS_URL:"http://localhost:8099",
            APP_ENGINE_ASYNC_URL:"http://localhost:8098",

            FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-d2-dev-6a857.appspot.com/o/",
            RECAPCTHA_KEY:"6Ldtna0aAAAAAPB4YZZnFp6evOIUnoqCeI88j6US",
            CVENT_REGISTRATION_FORM_URL:"https://www.cvent.com/Events/APIs/Reg.aspx",
            // CVENT_REGISTRATION_FORM_URL:"https://sandbox-www.cvent.com/Events/APIs/Reg.aspx",

            GTM_CODE:"GTM-WRLV2CG",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyAQXVRpzMLHY-WGoekYVnsCYdH2H974OFg",
                authDomain: "allergan-ami-d2-dev-6a857.firebaseapp.com",
                projectId: "allergan-ami-d2-dev-6a857",
                storageBucket: "allergan-ami-d2-dev-6a857.appspot.com",
                messagingSenderId: "187207163157",
                appId: "1:187207163157:web:47710a76c87f9cfee69d1c",
                measurementId: "G-M58WP2RPF6"
            }

        }
    }

    if(ENVIRONMENT===ENVIRONMENT_TYPES.DEV_REMOTE) {
        //
        // Dev Remote Server Config
        //
        return {

            APP_ENGINE_SERVICES_URL:"https://api.ami-dev-services.com",
            APP_ENGINE_TASKS_URL:"https://api.ami-dev-services.com",
            APP_ENGINE_ASYNC_URL:"https://api.ami-dev-services.com",

            // APP_ENGINE_SERVICES_URL:"https://ami-dev-api.d2clients.com",
            // APP_ENGINE_TASKS_URL:"https://ami-dev-api.d2clients.com",
            // APP_ENGINE_ASYNC_URL:"https://ami-dev-api.d2clients.com",

            FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-d2-dev-6a857.appspot.com/o/",
            RECAPCTHA_KEY:"6Ldtna0aAAAAAPB4YZZnFp6evOIUnoqCeI88j6US",
            CVENT_REGISTRATION_FORM_URL:"https://www.cvent.com/Events/APIs/Reg.aspx",

            GTM_CODE:"GTM-WRLV2CG",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyAQXVRpzMLHY-WGoekYVnsCYdH2H974OFg",
                authDomain: "allergan-ami-d2-dev-6a857.firebaseapp.com",
                projectId: "allergan-ami-d2-dev-6a857",
                storageBucket: "allergan-ami-d2-dev-6a857.appspot.com",
                messagingSenderId: "187207163157",
                appId: "1:187207163157:web:47710a76c87f9cfee69d1c",
                measurementId: "G-M58WP2RPF6"
            }
        }
    }


    if(ENVIRONMENT===ENVIRONMENT_TYPES.PRODUCTION) {
        //
        // Production Config
        //
        return {

            // APP_ENGINE_SERVICES_URL:"https://allergan-ami-production.uc.r.appspot.com",
            // APP_ENGINE_TASKS_URL:"https://allergan-ami-production.uc.r.appspot.com",
            // APP_ENGINE_ASYNC_URL:"https://allergan-ami-production.uc.r.appspot.com",

            APP_ENGINE_SERVICES_URL:"https://api.portal-prod-services.com",
            APP_ENGINE_TASKS_URL:"https://api.portal-prod-services.com",
            APP_ENGINE_ASYNC_URL:"https://api.portal-prod-services.com",

            FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-production.appspot.com/o/",
            RECAPCTHA_KEY:"6LeNg_QbAAAAAB45ktAccEsueYpJaoLD7k_3BLEG",
            CVENT_REGISTRATION_FORM_URL:"https://www.cvent.com/Events/APIs/Reg.aspx",

            GTM_CODE:"GTM-P8PJLJ5",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyA_zpR1dmZ5onBlVwpNi3t2ZJgOU-y_1qc",
                authDomain: "allergan-ami-production.firebaseapp.com",
                projectId: "allergan-ami-production",
                storageBucket: "allergan-ami-production.appspot.com",
                messagingSenderId: "990811543900",
                appId: "1:990811543900:web:5a0a5a7b14368a8cafc999",
                measurementId: "G-MC4TEGE11Z"
            }
        }
    }


    if(ENVIRONMENT===ENVIRONMENT_TYPES.WARNING__LOCAL_PRODUCTION) {

        //
        // WARNING: ONLY USE FOR TROUBLESHOOTING PRODUCTION
        //
        return {

            APP_ENGINE_SERVICES_URL:"http://localhost:8089",
            APP_ENGINE_TASKS_URL:"http://localhost:8099",
            APP_ENGINE_ASYNC_URL:"http://localhost:8098",
            FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-production.appspot.com/o/",
            RECAPCTHA_KEY:"6LeNg_QbAAAAAB45ktAccEsueYpJaoLD7k_3BLEG",
            CVENT_REGISTRATION_FORM_URL:"https://www.cvent.com/Events/APIs/Reg.aspx",

            GTM_CODE:"GTM-WRLV2CG",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyA_zpR1dmZ5onBlVwpNi3t2ZJgOU-y_1qc",
                authDomain: "allergan-ami-production.firebaseapp.com",
                projectId: "allergan-ami-production",
                storageBucket: "allergan-ami-production.appspot.com",
                messagingSenderId: "990811543900",
                appId: "1:990811543900:web:5a0a5a7b14368a8cafc999",
                measurementId: "G-MC4TEGE11Z"
            }
        }
    }
};
